.wrapper {
  display: flex;
  background-color: var(--mantine-color-background);
  border-radius: var(--mantine-radius-lg);
  padding: 4;

  @media (max-width: $mantine-breakpoint-sm) {
    width: 90vw;
    flex-direction: column;
  }
}

.form {
  box-sizing: border-box;
  flex: 1;
  padding: var(--mantine-spacing-xl);
  padding-left: calc(var(--mantine-spacing-xl) * 2);

  @media (max-width: $mantine-breakpoint-sm) {
    padding: var(--mantine-spacing-md);
    padding-left: var(--mantine-spacing-md);
  }
}

.fields {
  margin-top: -12px;
}

.input {
  --input-label-size: var(--mantine-font-size-sm);
  --input-fz: var(--mantine-font-size-sm);

  @media (max-width: $mantine-breakpoint-sm) {
    --input-label-size: var(--mantine-font-size-md);
    --input-fz: var(--mantine-font-size-md);
  }

  & label {
    line-height: 2.5;
  }
}

.notification {
  border-color: var(--mantine-color-dark-4);
}

.contacts {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding: var(--mantine-spacing-xl);
  flex: 0 0 15rem;

  &:before {
    content: "";
    position: absolute;
    right: -1px;
    top: 5%;
    height: 90%;
    border-right: 1px solid var(--mantine-primary-color);
  }

  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-sm);
    padding-left: var(--mantine-spacing-md);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);

    &:before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 5%;
      width: 90%;
      border-right: none;
      border-bottom: 1px solid var(--mantine-primary-color);
    }
  }
}

.title {
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  font-weight: 700;

  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-xl);
  }
}

.button {
  --button-height: var(--button-height-sm);
  --button-padding-x: var(--button-padding-x-sm);
  --button-fz: var(--mantine-font-size-sm);

  @media (max-width: $mantine-breakpoint-sm) {
    flex: 1;
    --button-height: var(--button-height-lg);
    --button-padding-x: var(--button-padding-x-lg);
    --button-fz: var(--mantine-font-size-lg);
  }
}
