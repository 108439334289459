.title {
  text-align: center;
}

.services {
  max-width: var(--mantine-breakpoint-xl);
  padding: calc(var(--mantine-spacing-xl) * 4) 0;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: calc(var(--mantine-spacing-xl) * 2 + var(--mantine-breakpoint-xl))) {
    padding-left: calc(
      var(--mantine-spacing-xl) - ((100vw - $mantine-breakpoint-xl) / 2)
    );
    padding-right: calc(
      var(--mantine-spacing-xl) - ((100vw - $mantine-breakpoint-xl) / 2)
    );
  }

  @media (max-width: $mantine-breakpoint-xl) {
    padding-left: var(--mantine-spacing-xl);
    padding-right: var(--mantine-spacing-xl);
  }

  @media (max-width: $mantine-breakpoint-md) {
    padding-top: calc(var(--mantine-spacing-xl) * 3);
  }

  @media (max-width: $mantine-breakpoint-sm) {
    padding-top: calc(var(--mantine-spacing-xl) * 2);
  }

  @media (max-width: $mantine-breakpoint-xs) {
    padding-top: var(--mantine-spacing-xl);
  }
}

.contactBtnBox {
  margin-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.contactBtn {
  --button-color: var(--mantine-color-primary-4) !important;
}
